<template>
	<div v-if="authed">
		<splitpanes horizontal class="default-theme" style="height:calc(100vh - 52px)" @resized="setTableHeight" v-resize="setTableHeight">
			<pane min-size="20" size="35" ref="toppane">
				<v-data-table :headers="headers" :items="items" item-key="_id" v-model="selected"
					:options.sync="options" :server-items-length="totalItems" dense
					:footer-props="{ itemsPerPageOptions: [10, 30, 50] }" fixed-header :height="tableheight"
					:loading="loading" loading-text="加载中..." show-select single-select>
				<template v-slot:top>
					<div class="d-flex flex-wrap pb-1" style="gap:16px" ref="tabletopbar">
						<v-text-field placeholder="姓名/证件号/手机号" v-model="psearch" clearable hide-details
							append-icon="search" @click:append="loadData" @keyup.enter.native="loadData" style="flex: 0 1 auto" class="ml-2"/>
						<v-menu v-model="menu1" :close-on-content-click="false" :nudge-right="10"
							transition="scale-transition" offset-y min-width="290px">
							<template v-slot:activator="{ on, attrs }">
								<v-text-field :value="pdates.join(' ~ ')" label="预约手术日期" v-on="on" v-bind="attrs" readonly hide-details
									append-icon="mdi-close" @click:append="pdates=[]" style="flex: 0 1 auto;width:240px;"></v-text-field>
							</template>
							<v-date-picker v-model="pdates" @change="menu1=false" range locale="zh-cn" scrollable></v-date-picker>
						</v-menu>
						<v-checkbox v-model="showComplete" label="显示已完成此步骤" hide-details class="ml-8" @change="loadData"/>
					</div>
					<v-divider/>
				</template>
				<template v-slot:item.actions="{ item }">
					<v-tooltip bottom>
						<template v-slot:activator="{ on }">
							<v-icon v-on="on" @click="editNote(item)" class="mr-1" :color="item.color">edit_note</v-icon>
						</template>
						患者备注
					</v-tooltip>
				</template>
				<template v-slot:item.age="{ item }">
					{{getAge(item)}}
				</template>
				<template v-slot:item.opts="{ item }">
					{{getOptsStr(item)}}
				</template>
				<template v-slot:item.apntOprtDate="{ item }">
					{{formatTime(item.apntOprtDate)}}
				</template>
				</v-data-table>
			</pane>
			<pane min-size="20" size="65" v-if="(selected.length > 0)">
				<div style="max-height:100%;background-color:white;position:relative;" class="overflow-y-auto pl-2">
					<div style="position:sticky;top:0;z-index:1;border-bottom:1px solid #ccc;">
						<v-tabs v-model="tab">
							<v-tab>进度</v-tab>
							<v-tab>病历</v-tab>
							<v-tab>检中计费</v-tab>
						</v-tabs>
						<div :style="{display: tab===2 ? 'inline-block' : 'none'}" style="position:absolute;top:16px;left:580px;">
							<span style="font-size:14px;" class="mr-2">计费模板</span>
							<v-select :items="quotations" item-text="name" return-object v-model="quotation" dense hide-details outlined
								style="max-width:150px;font-size:12px;display:inline-block;" @change="onQuotationChanged" class="qtt"/>
						</div>
					</div>
					<v-tabs-items v-model="tab">
						<v-tab-item>
							<div class="d-flex pl-4">
								<v-form style="width:240px">
									<v-radio-group label="手术状态" v-model="stgOprt" mandatory>
										<v-radio v-for="r,i in stgOprtItems" :key="r" :label="r" :value="i===0?null:r"/>
									</v-radio-group>
									<v-btn color="primary" @click.stop="saveStage('内镜检查', stgOprt)" :loading="loading">保存</v-btn>
								</v-form>
							</div>
						</v-tab-item>
						<v-tab-item>
							<DiagnoseHistoryEditor ref="dhe" :pat="selected[0]"/>
						</v-tab-item>
						<v-tab-item style="max-width:800px;">
							<v-simple-table style="border:1px solid #cccccc;" dense class="mt-1">
								<template v-slot:default>
									<thead style="background-color: #eee">
										<tr>
											<th>诊疗项目</th>
											<th>计价说明</th>
											<th>数量</th>
										</tr>
									</thead>
									<tbody>
										<template v-for="(item, index) in quotation.treatment">
											<tr v-if="item.enabled || !!charges[index].quantity">
												<td width="50%">{{ item.subject }}</td>
												<td width="40%">{{ item.description }}</td>
												<td width="10%">
													<v-text-field v-model.number="charges[index].quantity" :min="0"
														type="number" single-line dense hide-details/>
												</td>
											</tr>
										</template>
									</tbody>
								</template>
							</v-simple-table>
							<v-card-actions class="mt-4 mb-8">
								<v-btn class="primary" style="width:120px" :loading="loading" :disabled="loading" @click.stop="saveCharges">保存</v-btn>
								<v-spacer/>
								<v-text-field label="合计" :value="chargeSum" dense hide-details readonly style="max-width:120px"/>
							</v-card-actions>
						</v-tab-item>
					</v-tabs-items>
	            </div>
			</pane>
		</splitpanes>
    </div>
    <div v-else>
        无此权限
    </div>
</template>

<script>
    import {formatTime, calculateAge} from '../utils'
    import { Splitpanes, Pane } from 'splitpanes'
    import 'splitpanes/dist/splitpanes.css'
	import DiagnoseHistoryEditor from '../components/DiagnoseHistoryEditor.vue'
	import {stgOprtItems, setPatientColor} from '../workset3'
	import PatientNote from '../components/PatientNote.vue'

    export default {
        data() {
            return {
                authed: false,
                headers: [
                    {text:'', value:'actions', width:40, sortable: false, class:'px-0', cellClass:'px-0'},
                    {text:'病案号', value:'pid', width:100},
                    {text:'姓名', value:'pii.username', width:100},
                    {text:'性别', value:'pii.gender', width:80},
                    {text:'年龄', value:'age', width:80},
                    {text:'电话', value:'pii.phone', width:100},
                    {text:'预约手术时间', value:'apntOprtDate', width:180},
                    {text:'套餐内容', value:'opts', width:180, sortable: false},
                ],
                items: [],
                loading: false,
				selected: [],
				options: {sortBy:['apntOprtDate'], sortDesc:[true]},
				totalItems: 0,
				tableheight: undefined,
				canEdit: true,
				psearch: '',
				tab: 0,
				stgOprtItems,
				stgOprt: null,
				menu1: false,
				pdates: [],
				quotation: {},		//quotation内容只能增不能减
				quotations: [],
                charges: [],
				showComplete: false,
            }
        },
        created() {
			this.formatTime = formatTime;
        },
        mounted() {
			this.authed = this.$hasPrivilege(['内镜检查']);
			if (!this.authed) return;
			this.canEdit = this.$hasPrivilege(['内镜检查']);
			this.fetchQuotation();
        },
        methods: {
			setTableHeight() {
				if (!this.$vuetify.breakpoint.mobile && this.selected.length === 0) {
					this.tableheight = undefined;
					return;
				}
				const tableFooterHeight = 39;
				setTimeout(() => {
    				const ttbh = this.$refs.tabletopbar.getBoundingClientRect().height;
					const paneHeight = this.$refs.toppane.$el.getBoundingClientRect().height;
					this.tableheight = paneHeight - tableFooterHeight - ttbh;
					if (this.selected.length > 0) this.$nextTick(() => this.$vuetify.goTo(".v-data-table__selected", { container: ".v-data-table__wrapper" }) );
				}, 200)
			},
			async loadData() {
				this.selected = [];
				if (this.options.page !== 1) {
					this.options.page = 1;	//自动触发fetchData
					return;
				}
				await this.fetchData();
			},
			async fetchData() {
				const db = this.$tcbapp.database();
				const _ = db.command;
				let filter = [];
				if (this.psearch) {
					filter.push(_.or([{'pii.username':this.psearch}, {'pii.phone':this.psearch}, {'pii.idcard':this.psearch}]));
				}
				if (this.pdates.length === 2) {
					const d1 = new Date(this.pdates[0] + 'T00:00:00+08:00');
					const d2 = new Date(this.pdates[1] + 'T23:59:59+08:00');
					filter.push({apntOprtDate:_.gt(d1).lt(d2)});
				}
				if (!this.showComplete) {
					filter.push({'stages.内镜检查':_.exists(false)})
				}
				filter.push({hide:_.neq(true)});
				filter.push({category:_.neq('clinic')});
				const f = filter;
				this.loading = true;
				try {
                    const countRes = await db.collection('wp3order').where(_.and(f)).count();
					this.totalItems = countRes.total;
					const { sortBy, sortDesc, page, itemsPerPage } = this.options;
					const res = await db.collection('wp3order').where(_.and(f)).orderBy(sortBy[0]||'_', sortDesc[0] ? 'desc' : 'asc')
						.skip((page-1) * itemsPerPage).limit(itemsPerPage)
						.get();
					await setPatientColor(db, res);
					this.items = res.data;
				} catch(err) {
					console.error(err);
				}
				this.loading = false;
			},
            getAge(item) {
                return calculateAge(item.pii.dob);
            },
            getOptsStr(item) {
				if (!item.opts) return '';
				return item.opts.GIE + '/' + item.opts.ANES + '/' + item.opts.PRO;
			},
			async saveStage(stage, value) {
				const id = this.selected[0]._id;
				this.loading = true;
				try {
					await this.$callCloudFunc({
						funcname:'saveStage',
						data: {
							id,
							stage,
							value,
						}
					});
					this.$dialog.message.success('保存完成');
					await this.fetchData();
				} catch(err) {
					this.$dialog.message.error('保存失败');
					console.error(err);
				}
				this.loading = false;
			},
			async fetchQuotation() {
				const db = this.$tcbapp.database();
				const _ = db.command;
				try {
					const res = await db.collection('wp3quotation').get();
					res.data.forEach(x => {
						x.treatment.map(y => {
							y.f = eval(y.f)
						})
					});
					this.quotations = res.data;
					this.quotation = this.quotations[0];
					this.charges = this.quotation.treatment.map(x => ({id:x.id, quantity:null}));
				} catch(err) {
					console.error(err);
				}
			},
			onQuotationChanged(newq) {
				this.charges = newq.treatment.map(x => ({id:x.id, quantity:null}));
			},
			async saveCharges() {
				const id = this.selected[0]._id;
				const qname = this.quotation.name;
				this.loading = true;
				try {
					await this.$callCloudFunc({
						funcname:'charging',
						data: {
							id,
							qname,
							charges: this.charges
						}
					});
					this.$dialog.message.success('保存完成');
					await this.fetchData();
				} catch(err) {
					console.error(err);				
					this.$dialog.message.error('保存失败');
				}
				this.loading = false;
			},
			async editNote(item) {
				await this.$dialog.showAndWait(PatientNote, {width:600, pid:item.pid});
				await this.fetchData();
			},
		},
		computed: {
			chargeSum() {
				if (!this.quotation.treatment) return 0;
				let sum = 0;
				for (let index in this.quotation.treatment) {
					if (this.charges[index].quantity) {
						sum += this.quotation.treatment[index].f(this.charges[index].quantity);
					}
				}
				return `￥${sum.toFixed(2)}`;
			},
		},
		watch: {
			options: {
				handler () {
					this.fetchData()
				},
				deep: true,
			},
			selected(newitem, olditem) {
				if (newitem.length === 0) return;
				this.stgOprt = newitem[0].stages?.['内镜检查'] || null;
				if (newitem[0].chargeQuotation) {
					const q = this.quotations.find(x => x.name === newitem[0].chargeQuotation);
					if (q) {
						this.quotation = q;
						if (newitem[0].charges) {
							this.charges = this.quotation.treatment.map(x => newitem[0].charges.find(y => y.id === x.id) || {id:x.id, quantity:null});
						} else {
							this.charges = this.quotation.treatment.map(x => ({id:x.id, quantity:null}));
						}
					}
				} else {
					this.quotation = this.quotations[0];
					this.charges = this.quotation.treatment.map(x => ({id:x.id, quantity:null}));
				}
			},
			pdates(ds) {
				if (ds.length === 1) return;
				if (ds.length === 2) {
					if (new Date(ds[0]) > new Date(ds[1])) {
						[ds[0], ds[1]] = [ds[1], ds[0]];
					}
				}
				this.loadData();
			},
		},
        components: { Splitpanes, Pane, DiagnoseHistoryEditor }
    }
</script>

<style>
.qtt .v-input__slot {
	min-height: 10px!important;
}
.qtt .v-select__selections {
	padding: 0!important;
}
.qtt .v-select__selection--comma {
	margin: 0!important;
}
.qtt input {
	padding: 0!important;
}
.qtt .v-input__append-inner {
	margin: 0!important;
}
</style>